/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/moment@2.28.0/moment.min.js
 * - /npm/moment@2.28.0/locale/de.min.js
 * - /npm/jquery-validation@1.19.2/dist/jquery.validate.min.js
 * - /npm/jquery-validation@1.19.2/dist/localization/messages_de.min.js
 * - /npm/uikit@3.21.10/dist/js/uikit.min.js
 * - /npm/flatpickr@4.6.6/dist/flatpickr.min.js
 * - /npm/flatpickr@4.6.6/dist/l10n/de.min.js
 * - /npm/chosen-js@1.8.7/chosen.jquery.min.js
 * - /npm/vanilla-lazyload@17.1.2/dist/lazyload.min.js
 * - /npm/@staaky/fresco@2.3.1/dist/js/fresco.min.js
 * - /npm/list.js@1.5.0/dist/list.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
